body {
  background-color: #f2f2f2;
  font-family: 'Avenir-light';
}

.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.video-content {
  z-index: 2;
}
.close-modal {
  display: block;
  position: relative;
  top: 2.2rem;
  left: -0.5rem;
  z-index: 9999;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  color: #000;
  background-color: white;
  opacity: 0.5;
  border-radius: 50%;
  float: right;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
}
.modalVideo {
  width: 80%;
  max-width: 1536px;
  height: auto;
  margin: auto;
}
.book-card:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}

.loader {
  border-top-color: #000;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

/*Agent Bio*/
.agentNameWrapper {
  min-height: 98px;
  display: block;
  width: 100%;
}

.agentName,
.agentBio,
.divTravelSpecialties,
.divTravelExperiences {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.addlInfo {
  margin-top: 20px;
  padding: 20px 0 20px 0;
  border-top: 1px solid #ebebeb;
}

.agentName h1 {
  font-size: 35pt;
  font-weight: 300;
  color: #f48024;
  text-transform: uppercase;
}

.agentBio p,
.travelSpecialtyTeaser,
.experience,
.agentReview,
.bioInfo,
.agentTestimonal p {
  line-height: 25px;
}

.travelSpecialty {
  margin: 20px 0;
  clear: both;
}

.travelSpecialtyImg,
.imgTravelImage {
  float: left;
  margin: 10px 20px 0 0;
  min-height: 105px;
  font-style: italic;
  text-align: center;
  font-size: 12px;
  background: #ebebeb;
  padding: 5px;
}

.travelSpecialtyImg img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.travelSpecialtyImg img:hover {
  opacity: 0.5;
}
.imgTravelImage img {
  width: 256px;
}

.travelSpecialtyImg img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  /*width: 100%;*/
  width: 256px;
}
.gm-style-iw-t {
  right: 0px;
  bottom: 43px !important;
}

.image-gallery-icon {
  scale: 50% !important;
  top: 35% !important;
  padding: 0px !important;
}
.image-gallery-left-nav {
  left: -80px !important;
}
.image-gallery-right-nav {
  right: -80px !important;
}

.image-gallery-thumbnail {
  width: 70px !important;
}

.large-hero {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  height: 15rem;
  width: 100%;
}

/* Blog Detail */
.blog-summary-thumbnail-image {
  width: 307px;
  height: 349px;
  object-fit: cover;
}

.ItineraryMargin {
  margin: 0px 0px 10px;
}
.CaptionMargin {
  margin: 0px 0px 15px;
}
.ImageBlock /*  Removing inline css to support global styling. */ {
  display: block;
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px; */
  width: 100%;
  margin: 0px 0px 8px;
}
.CorrectContainer {
  margin: 15px auto; /*  Changed from 50px to 15px  */
  /* max-width: 920px;  */
}
.ExtraPadding /*  Adding padding. The additional bottom padding helps with responsive spacing. */ {
  padding: 10px 10px 25px;
}

.InsertTemplate /*  Styling of instructional text */ {
  display: none;
  visibility: hidden;
}

.ButtonCenter {
  text-align: center;
  margin: 20px auto;
}

.pure-g [class*='pure-u'] {
  font-family: '', sans-serif !important;
}
.pure-u-1 p {
  line-height: 1.75rem;
}
.pure-u p {
  line-height: 1.75rem;
}
.pure-u .divBlogStory,
.divPromotionBody {
  font-size: 25px;
  font-weight: 350;
  color: #000000;
  line-height: 140%;
  padding-bottom: 20px !important;
}
/* Rarely Used */

.divBlogStory h1,
.divPromotionBody h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
/* Featured Sailings */

.divBlogStory h2,
.divPromotionBody h2 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Sailing Name */

.divBlogStory h3,
.divPromotionBody h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Style Available */

.divBlogStory h4,
.divPromotionBody h4 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Caption */

.divBlogStory h5,
.divPromotionBody h5 {
  font-size: 12px;
  font-weight: 500;
  padding-right: 10px !important;
  text-align: right;
  line-height: 1;
}
/* Terms & Conditions */

.divBlogStory h6,
.divPromotionBody h6 {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.25;
}
/* Style Available */

.divBlogStory h7,
.divPromotionBody h7 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.25;
}
/* Paragraph */
.divBlogStory ul,
.divPromotionBody ul,
.divBlogStory li,
.divPromotionBody li {
  /* font-size: unset; */
  list-style-type: disc;
  margin-left: 30px;
}
.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 640px) {
  .large-hero {
    height: 26rem;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Avenir-light';
  src: url('../public/fonts/Avenir/Avenir45Book/font.woff');
}
@font-face {
  font-family: 'Avenir-bold';
  src: url('../public/fonts/Avenir/Avenir65Medium/font.woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Avenir-light';
  }

  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }

  h4 {
    @apply text-lg;
  }

  p {
    @apply my-3;
  }
}

@layer components {
  .hidden-tw {
    @apply hidden;
  }
  .sf-form textarea,
  input,
  select {
    @apply bg-gtce9 p-3 text-base;
  }
  .divBlogStory p,
  .divPromotionBody p {
    @apply tracking-wider leading-9 text-xl md:text-lg font-normal;
  }
  .divBlogStory ul,
  .divPromotionBody ul,
  .divBlogStory li,
  .divPromotionBody li {
    @apply tracking-wider leading-9 text-xl md:text-lg font-normal;
  }
  .contact-form textarea,
  .contact-form input,
  .contact-form select,
  .contact-form textArea {
    @apply bg-white p-3 text-base;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

.image-scroll {
  /* width: 80%;
  max-width: 10000px; */
  width: 100%;
  max-width: 3960px;
  overflow: hidden;
}

.image-scroll .images {
  width: max-content;
  display: flex;
  flex-wrap: nowrap;
  gap: 2em;
  /* animation: swipe 2s linear infinite; */
}
.image-wrapper img {
  /* display: inline-flex; */
}

/* .image-wrapper:hover .images {
  animation-play-state: paused;
  white-space: 'nowrap';
} */

@keyframes move {
  to {
    transform: translate(calc(-50% - 1.5em));
  }
}
@keyframes swipe {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.swiper-pagination-bullet-active {
  background-color: #aeaeae !important;
}

.swiper-pagination-bullet {
  background-color: #aeaeae !important;
}
.swiper-button-prev {
  left: 60px !important;
  color: white !important;
  background-color: #47484c !important;
  width: 60px !important;
  height: 60px !important;
}
.swiper-button-next {
  right: 60px !important;
  color: white !important;
  background-color: #47484c !important;
  width: 60px !important;
  height: 60px !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  scale: 0.7;
}

.contact-us-swiper .swiper-button-prev {
  left: -60px !important;
}

.clipped-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(153, 154, 155, 0.4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.imgGray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
